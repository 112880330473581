// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { Layout } from '../components/layout';
import { Content } from '../components/content';
import { Seo } from '../components/seo';

const TagsContent = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 20px 20px;
`;

const Title = styled.h1`
    font-size: 30px;
    text-align: center;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.darkBlue};
`;

const Tags = styled.ul`
    margin: 0;
    padding: 10px;
`;

const Tag = styled.li`
    display: inline-block;
    margin: 5px;
    list-style: none;
`;

const TagLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: inline-block;
    padding: 5px 10px;
    border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    background-color: ${({ theme }) => theme.colors.lightGray};
    transition: all 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray};
    }
`;

const TagsPage = ({ data }) => {
    const allTags = data.allMdx.group;

    return (
        <Layout>
            <Seo title='Tags' />
            <Content>
                <TagsContent>
                    <Title>Tags</Title>
                    <Tags>
                        {allTags.map(tag => (
                            <Tag key={tag.fieldValue}>
                                <TagLink to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                                    {tag.fieldValue}
                                    {' '}
                                    (
                                    {tag.totalCount}
                                    )
                                </TagLink>
                            </Tag>
                        ))}
                    </Tags>
                </TagsContent>
            </Content>
        </Layout>
    );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
